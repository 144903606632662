<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";
import router from "@/router/index.js";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Anniversaire",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Anniversaire",
          active: true,
        },
      ],
      dataUser: [],
      filteredData: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      showDateReducrion: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 500,
      pageOptions: [500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "cocher", label: "Cocher" },
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "birthday", sortable: true, label: "Anniversaire" },
        { key: "tel", sortable: true, label: "Téléphone" },
        // { key: "action", sortable: false, label: "Action" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      reduction: 1,
      datelimite: new Date(),
      newDataPromo: [],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataAnniverssaire: [],
      recherche: "",
      selected: [],
      activeToday: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.userTotal();
    this.init();
    //this.totalInfo();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/users-actif",
        undefined,
        false
      );
      if (data && data.data) {
        var countUserWithDate = data.data.filter(function (el) {
          return (
            el.birthday != null &&
            el.birthday != 0 &&
            el.lastname &&
            el.firstname &&
            el.id &&
            (el.birthday.split("/")[2] || el.birthday.split("-")[2])

          );
        });

        const formattedTable = countUserWithDate.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var lenghtuserDate = user.birthday.split("/");
          console.log(lenghtuserDate.length);
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            birthday: user.birthday,
          };
        });
        this.dataUser = formattedTable;
        this.totalRows = formattedTable.length

      }
    },

    position() {
      Vue.swal({
        position: "center",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    positionOff() {
      Vue.swal({
        position: "center",
        icon: "error",
        title: "Erreur. Réessayer.",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async envoyer() {

      console.log("this selected", this.selected)
      if (this.reduction < 1 || this.selected.length == 0) {
        this.positionOff();
      } else {
        console.log("this selected", this.selected)
        const data = await apiRequest(
          "POST",
          "codepromo/annif",
          {
            reduction: this.reduction,
            dateLimite: this.datelimite,
            user: this.userToUse,
            userList: this.selected,
          },
          false
        );

        if (data && data.data) {
          this.position();
          this.showDateReducrion = false;
          this.flushAllSelected();
        }
      }
    },
    sendPromo() {
      console.log("this selected", this.selected)
      this.showDateReducrion = true;
    },

    async actifpage(page, limit) {
      //Chargement des données
      this.activeToday = false;
      const listeWallet = await apiRequest(
        "GET",
        "admin/users-actif?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        var countUserWithDate = listeWallet.data.filter(function (el) {
          return (
            el.birthday != null &&
            el.birthday != 0 &&
            el.lastname &&
            el.firstname &&
            el.id
            &&
            (el.birthday.split("/")[2] !== "" || el.birthday.split("-")[2] !== "")
          );
        });

        const formattedTable = countUserWithDate.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          console.log("resp", user.birthday.split("/")[2] !== "");
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            birthday: user.birthday,
          };
        });
        this.newDataAnniverssaire = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
        this.totalRows = datatotal.data.total;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",
        { mots: this.recherche },


        false
      );
      /*  if (dataSearch && dataSearch.data) {
         const formattedTableSearch = dataSearch.data.map((user) => {
           var nom = user.lastname == "" ? "Nom" : user.lastname;
           var prenom = user.firstname == "" ? "Prénom" : user.firstname;
           return {
             id: user.id,
             username: nom + " " + prenom,
             tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
             domicile: user.adresse,
             trajet: user.nombreTrajet,
             reservation: user.nombreReser,
           };
         });
         this.newDataDomnewDataPromoicile = formattedTableSearch;
 
         this.userChargeLoader = false;
         this.totalRows = formattedTableSearch.length;
       } 
  */

      if (dataSearch && dataSearch.data) {

        var countUserWithDate = dataSearch.data.filter(function (el) {
          return (
            el.birthday != null &&
            el.birthday != 0 &&
            el.lastname &&
            el.firstname &&
            el.id
            &&
            (el.birthday.split("/")[2] !== "" || el.birthday.split("-")[2] !== "")
          );
        });
        const formattedTable = countUserWithDate.map((user) => {

          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,

            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            birthday: user.birthday,
          };
        });
        this.newDataAnniverssaire = formattedTable;

        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
      }


    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifpage(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;

      this.actifpage(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifpage(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifpage(this.startValue, this.endValue);
    },


    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },

    // select() {
    //   if (this.filteredData.length == 0) {
    //     this.filteredData =
    //       this.newDataAnniverssaire.length != 0
    //         ? this.newDataAnniverssaire
    //         : this.dataUser;
    //     console.log("longueur null");
    //   }
    //   this.selected = new Array();

    //   for (let i in this.filteredData) {
    //     this.selected.push(this.filteredData[i].id);
    //   }
    //   console.log("this selected", this.selected)

    // },

    selectAll() {

      
      this.filteredData =
        this.newDataAnniverssaire.length != 0
          ? this.newDataAnniverssaire
          : this.dataUser;
      console.log("longueur null");

      this.filteredData.forEach(item => {

        if (this.selected.includes(item.id) == false) {
          this.selected.push(item.id)
          //item.selected =true
        }

      })
      console.log("alll", this.selected)
    },
    flushAllSelected() {
      this.selected = []
    }
  },

  watch: {
    async activeToday(newValue, oldValue) {
      console.log(oldValue);
      this.dataUser = []
      if (newValue === true) {
        this.userChargeLoader = true

        const data = await apiRequest(
          "GET",
          "admin/users-annifsday",
          undefined,
          false
        );
        if (data && data.data) {

          console.log("data.data", data.data)
          var userOfToday = data.data.annif.filter(function (el) {
            const today = new Date();
            const birthdayDate = new Date(el.birthdate).toISOString().slice(0,10);
            const todayDate = today.toISOString().slice(0,10);

            //console.log("todayDate", todayDate, "dateFromData", dateFromData);
            return (
            birthdayDate === todayDate &&
            el.lastname && 
            el.firstname &&
            el.id &&
          (el.birthday.split("/")[2] || el.birthday.split("-")[2])

            );
          });

          const formattedTable = userOfToday.map((user) => {
            var nom = user.lastname == "" ? "Nom" : user.lastname;
            var prenom = user.firstname == "" ? "Prénom" : user.firstname;

            console.log(user.id);
            return {
              id: user.id,
              username: nom + " " + prenom,
              tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
              birthday: user.birthday,
            };
          });
          this.userChargeLoader = false
          this.newDataAnniverssaire = formattedTable;
          this.totalRows = formattedTable.length;


        }
      }

      else {
        this.init();
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="dataUser.length == 0 && totalRows == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
            <div class="row d-flex justify-content-between p-2">
              <div class="card-title">
                <span> Total d'utilisateur : {{ totalRows }}</span>
              </div>

              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <div class="row d-flex justify-content-between align-items-center mt-4">
              <div class="p-3">

                <button class="btn btn-success bg-green" @click="selectAll">
                  Tout sélectionner
                </button>


              </div>

              <div class="p-3">

                <button class="btn btn-warning" @click="flushAllSelected">
                  Tout désélectionner
                </button>


              </div>
              <div class="p-3" v-if="selected.length != 0">
                <button class="btn bg-blue" @click="sendPromo()">
                  Réduction
                </button>
              </div>

              <!-- -->
              <div class="col-md-3">
                <div class="center">
                  <span class="mode">Tout</span>&nbsp;&nbsp;
                  <input type="checkbox" v-model="activeToday" :checked="activeToday" />&nbsp;&nbsp;
                  <span class="mode">Aujourd'hui</span>
                </div>
              </div>
              <!-- Search -->
              <div class="inputCol p-3">
                <div class="input">
                  <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                    @keyup.enter="userSearch" />
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table style="cursor: pointer" class="table-centered"
                :items="newDataAnniverssaire.length != 0 ? newDataAnniverssaire : dataUser" :fields="fields"
                responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                @row-clicked="goToDetail">
                <template v-slot:cell(cocher)="row">
                  <div class="specialChecker" v-if="dataUser.length > 0 || newDataAnniverssaire.length > 0">
                    <input type="checkbox" :value="row.item.id" v-model="selected" />
                  </div>

                  <!--<a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    @click="cancelAction(row.item)"
                    title="Désactiver"
                  >
                    <i class="mdi mdi-close font-size-18"></i>
                  </a>-->
                </template>

                <template v-slot:cell(reduction)="">
                  {{ dataUser.reduction }}
                </template>
                <template v-slot:cell(datelimite)="">
                  {{ dataUser.datelimite }}
                </template>

                <template v-slot:cell(tel)="row" style="text-align: center">
                  <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                    title="Envoyer un message whatsapp" target="_blank">
                    {{ row.value }}
                  </a>
                </template>
                <!--  <template v-slot:cell(action)="row">
                      <button
                        class="btn bg-green"
                        @click="sendPromo(row.item)"
                      >
                        Réduction
                      </button>
                    </template> -->
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                          data-placement="bottom" title="Prem">
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                          data-toggle="tooltip" data-placement="bottom" title="Préc">
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                          title="Suiv" :disabled="endValue >= totalValue">
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                          title="Dern" :disabled="endValue >= totalValue">
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-center" centered v-model="showDateReducrion" title="Code promotionnel" title-class="font-18"
      hide-footer>
      <div class="form-group">
        <label for="tite">Réduction</label>
        <input type="number" v-model="reduction" class="form-control" min="1" />
      </div>
      <div class="form-group">
        <label for="tite">Date limite</label>
        <input placeholder="repecter le format JJ/MM/AAAA " type="date" v-model="datelimite" class="form-control" />
      </div>

      <div class="form-group">
        <button class="btn bg-green float-right" @click="envoyer()">
          Ajouter
        </button>
      </div>
      <div class="form-group">
        <button class="btn btn-outline-warning" @click="showDateReducrion = false">
          Annuler
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
.fa-check-circle {
  font-size: 0.8rem;
  color: aquamarine;
}

thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3aaa35;
  border-color: #3aaa35;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.checker {
  margin-top: 0.444rem;
  display: inline-flex;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.656rem;
  margin-bottom: 0;
}

.specialChecker input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}

.specialChecker input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}

.specialChecker input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

.specialChecker input[type="checkbox"]:checked:after {
  display: block;
}

.bg-green {
  background: #3aaa35;
  color: #fff;
}


.bg-blue {
  background: #1c1cf0;
  color: #fff;
}


.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}

.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}

.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}
</style>
